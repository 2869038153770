import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import { Divider } from '../../components/dividers';
import {
  trackPrivacyPolicyViewed,
  trackTermsAndConditionsViewed,
  trackWebEvent,
} from '../../helpers/analytics';
import { FormattedMessage } from 'react-intl';
import { KeyRound, MailSearch, Wand2 } from 'lucide-react';
import {
  getSignInWithGoogleRedirectURL,
  getSignInWithZoomRedirectURL,
  sendSignInLinkToEmail,
  upsertState,
} from '../../helpers/authentication';
import { isEmailValid } from '../../helpers/utils';
import image_google from '../../img/gIcon.svg';
import image_zoom from '../../img/zoom-app.svg';
import { fetchApiV2 } from '../../helpers/api/helpers';
import { Link } from '../../components/Link';

const isZoomSignInEnabled = () => window.location.hash.match(/\?zoom=yeah$/);
const isRedirectToMeeting = () => {
  const redirect =
    window.location.hash.match(/redirect=(?<redirect>[^&]+)/)?.groups
      ?.redirect ?? '';
  return redirect.startsWith('/transcripts/');
};

const TermsAndPrivacyPolicyLinks = () => (
  <div className=" text-slate-400 text-xs">
    <FormattedMessage
      defaultMessage="By signing up, you agree to Tactiq's {terms} and {privacy}"
      id="ESckU/"
      values={{
        br: <br />,
        terms: (
          <Link
            blue
            onClick={() => trackTermsAndConditionsViewed(undefined)}
            to="https://tactiq.io/privacy/terms"
            target="_blank"
          >
            <FormattedMessage defaultMessage="Terms & Conditions" id="arPp4e" />
          </Link>
        ),
        privacy: (
          <Link
            blue
            onClick={() => trackPrivacyPolicyViewed(undefined)}
            target="_blank"
            to="https://tactiq.io/privacy/privacy"
          >
            <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
          </Link>
        ),
      }}
    />
  </div>
);

export const SignInBlock: React.FC<{
  oauthState: string;
  where: string;
}> = ({ oauthState, where }) => {
  const [currentEmail, setCurrentEmail] = useState('');
  const [isSendingEmailLink, setIsSendingEmailLink] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailUsedForGoogleSignIn, setIsEmailUsedForGoogleSignIn] =
    useState(false);
  const [isGoogleSignInRequiredByTeam, setIsGoogleSignInRequiredByTeam] =
    useState(false);

  const [showSSO, setSSO] = useState(false);
  const [isTryingSSO, setTryingSSO] = useState(false);

  const header = isRedirectToMeeting() ? (
    <>
      <div className="font-bold text-2xl">
        <p>👋</p>
        <p>
          <FormattedMessage defaultMessage="Sign in to access this meetings details" />
        </p>
      </div>
      <div className="mb-4">
        <FormattedMessage defaultMessage="The transcript and AI summary of this meeting were shared with you. Sign in to unlock and explore them." />
      </div>
    </>
  ) : (
    <>
      <div className="font-bold text-2xl text-slate-800">
        <p>👋</p>
        <p>
          <FormattedMessage defaultMessage="Log in or Sign up" />
        </p>
      </div>
      <div className="mb-4">
        <FormattedMessage defaultMessage="Turn meeting conversations into actionable outcomes. Log in or sign up to access transcripts, AI summaries, and workflows." />
      </div>
    </>
  );

  const continueWithGoogle = (
    <Button
      variant="neutral-primary"
      size="large"
      fullWidth
      href={getSignInWithGoogleRedirectURL(oauthState, [
        'https://www.googleapis.com/auth/calendar.events.readonly',
      ])}
      onClick={() => {
        trackWebEvent('Clicked Continue with Google', { where });
      }}
      disabled={isSendingEmailLink}
      startIcon={<img src={image_google} alt="Google" className="h-6 w-6" />}
    >
      <FormattedMessage defaultMessage="Continue with Google" />
    </Button>
  );

  if (isEmailUsedForGoogleSignIn) {
    return (
      <div className="flex flex-col gap-4 rounded-dialog border border-neutral-tertiary bg-white p-8">
        {isGoogleSignInRequiredByTeam ? (
          <>
            <div className="font-bold text-3xl">
              <p>✋</p>
              <p>
                <FormattedMessage defaultMessage="Hold on a moment!" />
              </p>
            </div>
            <p>
              <FormattedMessage defaultMessage="Your team requires you to use your Google account to sign in." />
            </p>
          </>
        ) : (
          <>
            <div className="font-bold text-3xl">
              <p>👋</p>
              <p className="font-semibold text-2xl">
                <FormattedMessage defaultMessage="Sign in with your existing account" />
              </p>
            </div>
            <p>
              <FormattedMessage
                defaultMessage="You’ve already signed up to <b>Tactiq.io</b> using your Google account. Use it to sign in below."
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          </>
        )}

        {continueWithGoogle}

        <Button
          variant="neutral-tertiary"
          size="large"
          fullWidth
          onClick={() => {
            setCurrentEmail('');
            setIsSendingEmailLink(false);
            setIsEmailSent(false);
            setIsEmailUsedForGoogleSignIn(false);
            setIsGoogleSignInRequiredByTeam(false);
            trackWebEvent('Clicked Cancel when asked to log in with Google', {
              where,
            });
          }}
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
      </div>
    );
  }

  if (isEmailSent) {
    return (
      <div className="flex flex-col gap-4 rounded-dialog border border-neutral-tertiary bg-white p-8">
        <div className="flex flex-col items-center gap-4 font-semibold text-2xl">
          <MailSearch className="size-12" />
          <FormattedMessage defaultMessage="An email is on its way!" />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="We just sent an email to <b>{currentEmail}</b>. You’ll find a magic link that will sign you into your account."
            values={{
              b: (chunks) => <b>{chunks}</b>,
              currentEmail,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="The link expires in <b>24 hours</b>, so make sure you use it soon!"
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </div>

        <Divider />

        <div className="text-sm">
          <FormattedMessage defaultMessage="Didnt receive an email? Please check your Spam folder. You can try again in 1 minute." />
        </div>

        <Button
          variant="neutral-tertiary"
          size="large"
          fullWidth
          onClick={() => setIsEmailSent(false)}
        >
          <FormattedMessage defaultMessage="Go back" />
        </Button>
      </div>
    );
  }

  if (showSSO) {
    return (
      <div className="flex flex-col gap-4 rounded-dialog border border-neutral-tertiary bg-white p-8 text-slate-600">
        {header}
        <TextInput
          type="email"
          placeholder="you@example.com"
          value={currentEmail}
          disabled={isSendingEmailLink}
          onChange={setCurrentEmail}
        />

        <Button
          variant="neutral-primary"
          size="large"
          fullWidth
          loading={isTryingSSO}
          disabled={isTryingSSO}
          onClick={async () => {
            const email = currentEmail.trim();

            if (!email) {
              enqueueSnackbar('Please enter your email', {
                variant: 'ERROR',
              });
              return;
            }

            if (!isEmailValid(email)) {
              enqueueSnackbar('Please enter a valid email', {
                variant: 'ERROR',
              });
              return;
            }

            trackWebEvent('Sign in with SSO', { where });
            setTryingSSO(true);

            try {
              const result = await fetchApiV2<{
                url?: string;
              }>('/u/auth/sso', {
                method: 'POST',
                body: JSON.stringify({
                  email,
                }),
              });
              setTryingSSO(false);
              if (result.url) {
                window.location.href = result.url;
              } else {
                enqueueSnackbar(
                  'Your team might not have SSO enabled, please try again later',
                  {
                    variant: 'ERROR',
                  }
                );
              }
            } catch (error) {
              setTryingSSO(false);
              enqueueSnackbar('SSO failed, please try again later', {
                variant: 'ERROR',
              });
            }
          }}
          startIcon={<KeyRound size="1rem" />}
        >
          <FormattedMessage defaultMessage="Sign in with SSO" />
        </Button>

        <div className="text-center">
          <Button
            variant="text"
            className="text-center text-neutral-primary text-sm"
            onClick={() => setSSO(false)}
          >
            Sign in with Google or Email Link
          </Button>
        </div>
        <TermsAndPrivacyPolicyLinks />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 rounded-dialog border border-neutral-tertiary bg-white p-8 text-slate-600">
      {header}
      {continueWithGoogle}
      {isZoomSignInEnabled() && (
        <div>
          <Button
            variant="neutral-primary"
            size="large"
            fullWidth
            href={getSignInWithZoomRedirectURL(oauthState)}
            disabled={isSendingEmailLink}
            onClick={() =>
              trackWebEvent('Click Sign in with Zoom', { mode: 'api' })
            }
            startIcon={
              <img src={image_zoom} height="26" width="26" alt="Zoom icon" />
            }
          >
            <FormattedMessage defaultMessage="Continue with Zoom" />
          </Button>
        </div>
      )}

      <Divider
        label={<FormattedMessage defaultMessage="Or use your email" />}
      />

      <TextInput
        type="email"
        placeholder="you@example.com"
        value={currentEmail}
        disabled={isSendingEmailLink}
        onChange={setCurrentEmail}
      />

      <Button
        variant="neutral-primary"
        size="large"
        fullWidth
        loading={isSendingEmailLink}
        onClick={async () => {
          const email = currentEmail.trim();

          if (!email) {
            enqueueSnackbar('Please enter your email', {
              variant: 'ERROR',
            });
            return;
          }

          if (!isEmailValid(email)) {
            enqueueSnackbar('Please enter a valid email', {
              variant: 'ERROR',
            });
            return;
          }

          trackWebEvent('Clicked Send a magic link', { where });
          setIsSendingEmailLink(true);

          const state = await upsertState(
            { email, language: navigator.language },
            oauthState
          );
          try {
            const result = await sendSignInLinkToEmail(state);
            setIsSendingEmailLink(false);
            if (result.ssoUrl) {
              window.location.href = result.ssoUrl;
            } else if (result.requireGoogleSignIn) {
              setIsEmailUsedForGoogleSignIn(true);
              setIsGoogleSignInRequiredByTeam(Boolean(result.team));
            } else {
              setIsEmailSent(true);
            }
          } catch (error) {
            setIsSendingEmailLink(false);
            enqueueSnackbar('Could not send an email, please try again later', {
              variant: 'ERROR',
            });
          }
        }}
        startIcon={<Wand2 size="1rem" />}
      >
        <FormattedMessage defaultMessage="Get a magic link" />
      </Button>

      <div className="text-center">
        <Button
          variant="text"
          className="text-center text-neutral-primary text-sm"
          onClick={() => setSSO(true)}
        >
          Use Single Sign-On instead
        </Button>
      </div>
      <TermsAndPrivacyPolicyLinks />
    </div>
  );
};
