import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Menu } from '../../components/Menu';
import { Tooltip } from '../../components/Tooltip';
import { Button } from '../../components/buttons';
import { forceSignOut } from '../../helpers/authentication';
import { kContact } from '../../helpers/routes';
import logo from '../../img/Tactiq.svg';
import defaultUserIcon from '../../img/user.svg';
import { selectUserEmail, selectUserName } from '../../redux/selectors';
import { RootState } from '../../redux/store';

const NavSettingAvatarMicroClasses = 'h-7 w-7 rounded-full';

const LogoImageClasses = 'w-[106px] h-[43px]';

/**
 * Logo component
 */
export const Logo: React.FC = () => {
  return <img className={LogoImageClasses} src={logo} alt="Tactiq Logo" />;
};

/**
 * User picture dropdown component
 */
export const UserPictureDropDown: React.FC = () => {
  const userEmail = useSelector(selectUserEmail);
  const userName = useSelector(selectUserName) ?? 'You';
  const userPhotoURL = useSelector((state: RootState) => state.user.photoURL);

  return (
    <Tooltip title={userEmail} placement="right">
      <Menu>
        <Menu.Trigger>
          <Button fullWidth variant="naked">
            <div className="mr-auto flex items-center gap-2.5">
              <img
                className={NavSettingAvatarMicroClasses}
                alt={userName}
                src={userPhotoURL ?? defaultUserIcon}
              />
              <div className="flex flex-col">
                <div className="max-w-[140px] overflow-hidden text-ellipsis font-medium text-neutral-default text-sm">
                  {userName}
                </div>
                <div className="max-w-[175px] overflow-hidden text-ellipsis font-normal text-neutral-subtle text-xs">
                  {userEmail}
                </div>
              </div>
            </div>
          </Button>
        </Menu.Trigger>
        <Menu.Item onClick={() => window.open(kContact)}>
          <FormattedMessage
            defaultMessage="Contact Us"
            description="Contact us page link text."
          />
        </Menu.Item>
        <Menu.Item
          onClick={async () => {
            return forceSignOut('navigation');
          }}
        >
          <FormattedMessage
            defaultMessage="Sign out"
            description="Sign out button text."
          />
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};
